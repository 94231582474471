/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Col, Row, Form, InputGroup, Button } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import { useNavigate } from 'react-router-dom';
import { backgroundImage } from '../../../utils/AppConstants';
import SocialIcons from '../SocialIcons';
import { useDispatch, useSelector } from 'react-redux';
import { setFormView } from '../../../store/slices/authPageSlice';
import back from '../../../assets/icons/back.svg';
import Step from '../../../models/step.interface';
import { AppDispatch, RootState } from '../../../store/store';
import {
  setName,
  setSector,
  setWebsite,
} from '../../../store/slices/companySlice';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { getWebsiteRegex } from '../../../utils/get-website-regex';

function SignUpForm(props: Step) {
  const { t } = useTranslation();
  const company = useSelector((state: RootState) => state.companyReducer);
  const dispatch = useDispatch<AppDispatch>();
  const history = useNavigate();

  const companyMainSchema = object({
    name: string().required(t('errors.fieldRequired')),
    industrialSector: string().required(t('errors.fieldRequired')),
    website: string()
      .matches(getWebsiteRegex(), t('errors.correctUrl'))
      .required(t('errors.fieldRequired')),
  });

  return (
    <div
      className='bg-image h-100 w-100 d-flex justify-content-center align-items-center'
      style={backgroundImage}
    >
      <div className='main'>
        <button
          onClick={() => dispatch(setFormView(false))}
          className='auth-back'
        >
          <img src={back} alt='back' />
        </button>
        <div className='form text-start'>
          <h2 className='register text-center'>
            {t('registrationForm.title')}
          </h2>
          <p className='confrom  text-center'>
            {t('registrationForm.subtitle')} {}
            <span>
              <a
                className='cursor-pointer'
                onClick={() => history('/auth/company/sign-in')}
              >
                {t('registrationForm.loginUrl')}
              </a>
            </span>
          </p>
          <Formik
            initialValues={{
              name: company.name,
              industrialSector: company.industrialSector,
              website: company.website,
            }}
            onSubmit={(values) => {
              dispatch(setName(values.name));
              dispatch(setSector(values.industrialSector));
              dispatch(setWebsite(values.website));

              props.handleNext();
            }}
            validationSchema={companyMainSchema}
          >
            {({ errors, touched }) => (
              <FormikForm>
                <Row className='mt-2'>
                  <Form.Group as={Col} xs={12} controlId='validationCustom01'>
                    <Form.Label className='input-label'>
                      {t('companyRegistration.nameLabel')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        className='form-control auth-input'
                        type='text'
                        aria-describedby='inputGroupPrepend'
                        name='name'
                      />
                      {errors.name && touched.name ? (
                        <p className='text-danger position-absolute error-message candidate'>
                          {errors.name}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} controlId='validationCustom02'>
                    <Form.Label className='input-label'>
                      {t('companyRegistration.sectorLabel')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        className='form-control auth-input'
                        type='text'
                        aria-describedby='inputGroupPrepend'
                        name='industrialSector'
                      />
                      {errors.industrialSector && touched.industrialSector ? (
                        <p className='text-danger error-message candidate'>
                          {errors.industrialSector}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={12}
                    controlId='validationCustomUsername'
                  >
                    <Form.Label className='input-label'>
                      {t('companyRegistration.websiteLabel')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        className='form-control auth-input'
                        type='text'
                        aria-describedby='inputGroupPrepend'
                        name='website'
                      />
                      {errors.website && touched.website ? (
                        <p className='text-danger error-message candidate'>
                          {errors.website}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Button className='w-100 p-3 submitbtn' type='submit'>
                  {t('registrationForm.submitButton')}
                </Button>
              </FormikForm>
            )}
          </Formik>
        </div>
        {/*
        <h3>{t('registrationForm.registrationProposal')}</h3>
        <SocialIcons />
        */}
      </div>
    </div>
  );
}

export default SignUpForm;
