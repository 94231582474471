import React, { useState } from "react";
import SideContent from "./SideContent";
import SignUpForm from "./SignUp/SignUpForm";
import RegistrationStep1 from "./Registration Form/RegistrationStep1";
import RegistrationStep2 from "./Registration Form/RegistrationStep2";
import RegistrationStep3 from "./Registration Form/RegistrationStep3";
import RegistrationStep4 from "./Registration Form/RegistrationStep4";
import RegistrationStep5 from "./Registration Form/RegistrationStep5";
import RegistrationStep6 from "./Registration Form/RegistrationStep6";
import SubmittedForm from "./Registration Form/SubmittedForm";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const steps = [
  { title: "Step 1", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <SignUpForm currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 2", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep1 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 3", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep2 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 4", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep3 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 5", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep4 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 6", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep5 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 7", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep6 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 8", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <SubmittedForm currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> }
];

export default function SignUp() {
  const [currentStep, setCurrentStep] = useState(0);
  const isFormView = useSelector((state: RootState) => state.authPageReducer.isFormView);
  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  return (
    <Row className="text-start">
      {
        currentStep === 0 && <Col className={`p-0 ${isFormView ? 'tablet-hidden' : ''}`} xs={12} lg={6}>
          <SideContent isCompany={true} />
        </Col>
      }
      <Col className={`p-0 ${currentStep === 8 ? "offset-md-2" : ''} ${currentStep === 0 && !isFormView  ? 'tablet-hidden' : ''}`} xs={12} lg={currentStep === 0 ? 6 : 12}>
        {steps[currentStep].component(currentStep, handleNext, handlePrev)}
      </Col>
    </Row>
  );
}
