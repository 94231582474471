import React from 'react';
import { Row, Col, Image, Form, InputGroup } from 'react-bootstrap';
import RegistrationActionButton from '../../../components/RegistrationActionButton';
import logo from '../../../assets/images/job-portal/zestur-logo-internal.svg';
import { ASSETS_URL } from '../../../constants';
import { backgroundImage } from '../../../utils/AppConstants';
import { Field, Formik, Form as FormikForm } from 'formik';
import Step from '../../../models/step.interface';
import { object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { setCompanyContactInfo } from '../../../store/slices/companySlice';
import { checkEmail } from '../../../store/slices/emailCheckSlice';
import { useTranslation } from 'react-i18next';
import RestrictedInput from '../../../components/ui/restricted-input/RestrictedInput';
import ProgressBarTitle from '../../../components/ui/progress-bar-title/ProgressBarTitle';
import MobileProgress from '../../../components/mobile-progress/MobileProgress';

function RegistrationStep5(props: Step) {
  const { t } = useTranslation();
  const company = useSelector((state: RootState) => state.companyReducer);
  const dispatch = useDispatch<AppDispatch>();
  const logoURL: string = `${ASSETS_URL}`;
  const validationSchema = object({
    firstName: string().required(t('errors.fieldRequired')),
    lastName: string().required(t('errors.fieldRequired')),
    email: string()
      .email(t('errors.emailInvalid'))
      .required(t('errors.fieldRequired')),
    phoneCode: string().required(t('errors.fillCode')),
    phoneNumber: string().required(t('errors.fieldRequired')),
  });

  return (
    <div className='bg-white register-form'>
      <Row>
        <Col xs={12} className='no-mobile-hidden'>
          <MobileProgress
            data={props}
            progressValue={83}
            startStep={5}
            endStep={6}
          />
        </Col>
        <Col xs={12} md={3} className='mobile-hidden'>
          <div className='register-sidebar' style={backgroundImage}>
            <Image
              className='ms-3 mt-3'
              src={logoURL + logo}
              alt='logo Image'
              fluid
            />
          </div>
        </Col>

        <Col xs={12} className='no-mobile-hidden'>
          <h2 className='text-main-black px-4'>
            {t('companyRegistration.step5.title')}
          </h2>
        </Col>

        <Col
          xs={12}
          md={9}
          className='d-flex justify-content-center justify-items-center'
        >
          <Formik
            initialValues={{
              firstName: company.firstName,
              lastName: company.lastName,
              email: company.email,
              phoneCode: company.phoneCode,
              phoneNumber: company.phoneNumber,
            }}
            onSubmit={(values) => {
              dispatch(checkEmail(values.email)).then((res: any) => {
                if (!res.error) {
                  props.handleNext();
                }
              });

              dispatch(setCompanyContactInfo(values));
            }}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
                {/* main-ProgressBar */}
                <div className='main-ProgressBar mobile-hidden'>
                  <ProgressBarTitle
                    startStep={5}
                    endStep={6}
                    progressValue={83}
                  />
                  <h2>{t('companyRegistration.step5.title')}</h2>
                </div>

                {/* form-fields */}
                <Row className='form-fields candidate'>
                  <Row>
                    <Form.Group
                      as={Col}
                      md={6}
                      xs={12}
                      controlId='validationCustom02'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step5.nameLabel')}*
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <RestrictedInput
                              setFieldValue={setFieldValue}
                              fieldValue={values.firstName}
                              valueName='firstName'
                              restriction='text'
                            />
                          )}
                        </Field>
                        {errors.firstName && touched.firstName ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.firstName}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md={6}
                      xs={12}
                      className='mobile-mt-2'
                      controlId='validationCustom03'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step5.surnameLabel')}*
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <RestrictedInput
                              setFieldValue={setFieldValue}
                              fieldValue={values.lastName}
                              valueName='lastName'
                              restriction='text'
                            />
                          )}
                        </Field>
                        {errors.lastName && touched.lastName ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.lastName}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      className='mobile-mt-2'
                      controlId='validationCustom01'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step5.emailLabel')}*
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 px-4 py-3'
                          type='text'
                          placeholder=''
                          aria-describedby='inputGroupPrepend'
                          name='email'
                        />
                        {errors.email && touched.email ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.email}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className='align-items-end'>
                    <Form.Group
                      controlId='validationCustom02'
                      as={Col}
                      md={2}
                      xs={3}
                      style={{ paddingRight: 0 }}
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step5.phoneLabel')}*
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 text-center py-3'
                          type='text'
                          placeholder='+34'
                          aria-describedby='inputGroupPrepend'
                          name='phoneCode'
                        />
                        {errors.phoneCode && touched.phoneCode ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.phoneCode}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md={10}
                      xs={9}
                      controlId='validationCustom02'
                      className='mobile-mt-2'
                    >
                      <Form.Label className='label'></Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <div className='w-100'>
                              <RestrictedInput
                                setFieldValue={setFieldValue}
                                fieldValue={values.phoneNumber}
                                valueName='phoneNumber'
                                restriction='number'
                              />
                            </div>
                          )}
                        </Field>
                        {errors.phoneNumber && touched.phoneNumber ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.phoneNumber}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Row>

                <Row className='mt-4 no-mobile-hidden'></Row>

                <RegistrationActionButton
                  data={props}
                  isSubmit={true}
                ></RegistrationActionButton>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default RegistrationStep5;
