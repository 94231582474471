import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { getAssignedCandidates } from '../../../store/slices/companyCandidatesSlice';
import CandidateTableFilter from '../../../components/candidates-table/CandidateTableFilter';
import CandidatesTable from '../../../components/candidates-table/CandidatesTable';
import { getTableExperienceYears } from '../../../utils/get-table-experience-year';
import { AssignedCandidate } from '../../../models/assigned-candidate.interface';
import { useTranslation } from 'react-i18next';

const ApplicantsCompany = () => {
  const { t } = useTranslation();
  const candidates = useSelector(
    (state: RootState) => state.companyCandidatesReducer.assignedCandidates
  );
  const [displayedCandidates, setDisplayedCandidates] = useState<
    AssignedCandidate[]
  >([]);
  const dispatch = useDispatch<AppDispatch>();

  const [statusValue, setStatusValue] = useState<string>('');
  const [durationSortDescending, setDurationSortDescending] =
    useState<boolean>(true);
  const [nameSortDescending, setNameSortDescending] = useState<boolean>(true);
  const [vacancyNameSortDescending, setVacancyNameSortDescending] =
    useState<boolean>(true);
  const [matchSortDescending, setMatchSortDescending] = useState<boolean>(true);
  const [experienceSortDescending, setExperienceSortDescending] =
    useState<boolean>(true);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxAmount, setMaxAmount] = useState<number>(10);

  useEffect(() => {
    dispatch(
      getAssignedCandidates({
        pageNumber: 1,
        searchValue,
        size: maxAmount,
        statusValue,
      })
    );
  }, []);

  useEffect(() => {
    setDisplayedCandidates(candidates);
  }, [candidates]);

  function sortByName(): void {
    uncheckAllRows();
    setPageNumber(1);
    dispatch(
      getAssignedCandidates({
        pageNumber: 1,
        searchValue: searchValue,
        size: maxAmount,
        statusValue: statusValue,
        name: nameSortDescending,
      })
    );

    setNameSortDescending((prev) => !prev);
  }

  function sortByVacancyName(): void {
    uncheckAllRows();
    setPageNumber(1);
    dispatch(
      getAssignedCandidates({
        pageNumber: 1,
        searchValue: searchValue,
        size: maxAmount,
        statusValue: statusValue,
        position: vacancyNameSortDescending,
      })
    );

    setVacancyNameSortDescending((prev) => !prev);
  }

  function sortByDuration(): void {
    setDisplayedCandidates((prev) => {
      const copy = [...prev];
      copy.sort((a, b) => {
        if (!a.candidate.experience[0]) return -1;
        if (!b.candidate.experience[0]) return 1;
        return (
          getTableExperienceYears(
            durationSortDescending
              ? b.candidate.experience[0]
              : a.candidate.experience[0]
          ) -
          getTableExperienceYears(
            durationSortDescending
              ? a.candidate.experience[0]
              : b.candidate.experience[0]
          )
        );
      });
      return copy;
    });
    setDurationSortDescending((prev) => !prev);
  }

  function sortByUserStatus(): void {
    // candidates.sort((a, b) =>
    //   a.first_name
    //     .cocat(' ', a.last_name)
    //     .localeCompare(b.first_name.cocat(' ', b.last_name))
    // );
  }

  function sortByMatch(): void {
    // uncheckAllRows();
    // setPageNumber(1);
    // dispatch(
    //   getAssignedCandidates({
    //     pageNumber: 1,
    //     searchValue: searchValue,
    //     size: maxAmount,
    //     onlySaved: isSavedFilter,
    //     aiMatch: matchSortDescending,
    //   })
    // );
    setDisplayedCandidates((prev) => {
      const copy = [...prev];
      copy.sort(
        (a, b) =>
          +(a.ai_match?.[0]?.ai_match || 0) - +(b.ai_match?.[0]?.ai_match || 0)
      );

      if (matchSortDescending) {
        copy.reverse();
      }

      return copy;
    });

    setMatchSortDescending((prev) => !prev);
  }

  function sortByExperience(): void {
    setDisplayedCandidates((prev) => {
      const copy = [...prev];
      copy.sort((a, b) => {
        if (!a.candidate.experience[0]) return -1;
        if (!b.candidate.experience[0]) return 1;
        return a.candidate.experience[0].company
          .concat(' ', a.candidate.experience[0].job_position)
          .localeCompare(
            b.candidate.experience[0].company.concat(
              ' ',
              b.candidate.experience[0].job_position
            )
          );
      });
      if (experienceSortDescending) {
        copy.reverse();
      }
      return copy;
    });
    setExperienceSortDescending((prev) => !prev);
  }

  function handleRowCheck(id: string): void {
    setDisplayedCandidates((prev) => {
      const copy: AssignedCandidate[] = JSON.parse(JSON.stringify(prev));
      const selectedElementIndex = copy.findIndex((item) => item.id === id);
      copy[selectedElementIndex].checked = !copy[selectedElementIndex].checked;

      return copy;
    });
  }

  function filterByOffer(filterType: string): void {
    uncheckAllRows();
    setPageNumber(1);
    setSearchValue(filterType);
    setDisplayedCandidates(candidates);

    dispatch(
      getAssignedCandidates({
        pageNumber: 1,
        searchValue: filterType,
        size: maxAmount,
        statusValue: statusValue,
      })
    );
  }

  function filterByStatus(filterStatus: string): void {
    uncheckAllRows();
    setPageNumber(1);
    setStatusValue(filterStatus);
    setDisplayedCandidates(candidates);

    dispatch(
      getAssignedCandidates({
        pageNumber: 1,
        searchValue: searchValue,
        size: maxAmount,
        statusValue: filterStatus,
      })
    );
  }

  function uncheckAll(): void {
    setDisplayedCandidates((prev) => {
      const copy: AssignedCandidate[] = JSON.parse(JSON.stringify(prev));
      copy.map((item) => (item.checked = false));
      return copy;
    });
    setSelectAll(false);
  }

  function checkAll(): void {
    setDisplayedCandidates((prev) => {
      const copy: AssignedCandidate[] = JSON.parse(JSON.stringify(prev));
      copy.map((item) => (item.checked = true));
      return copy;
    });
    setSelectAll(true);
  }

  function uncheckAllRows(): void {
    setDisplayedCandidates((prev) => {
      const copy: AssignedCandidate[] = JSON.parse(JSON.stringify(prev));
      copy.map((item) => (item.checked = false));

      return copy;
    });

    setSelectAll(false);
  }

  function setMaxAmountHandler(value: number): void {
    setMaxAmount(value);
    uncheckAll();
    setPageNumber(1);
    dispatch(
      getAssignedCandidates({
        pageNumber: 1,
        searchValue,
        size: value,
        statusValue,
      })
    );
  }

  function setPageNumberHandler(page: number): void {
    setPageNumber(page);
    setSelectAll(false);
  }

  const columns = [
    {
      key: 'first_name',
      header: t('candidatesTablet.columns.name'),
      checkbox: true,
      sortFunction: sortByName,
    },
    {
      key: 'process',
      header: t('candidatesTablet.columns.vacancyName'),
      sortFunction: sortByVacancyName,
    },
    {
      key: 'match',
      header: t('candidatesTablet.columns.match'),
      sortFunction: sortByMatch,
    },
    {
      key: 'last_experience',
      header: t('candidatesTablet.columns.experience'),
      sortFunction: sortByExperience,
    },
    {
      key: 'durationOfLastJob',
      header: t('candidatesTablet.columns.duration'),
      sortFunction: sortByDuration,
    },
    {
      key: 'viewprofile',
      header: t('candidatesTablet.columns.link.header'),
      type: 'link',
      linkTitle: t('candidatesTablet.columns.link.button'),
    },
    {
      key: 'user_type',
      header: t('candidatesTablet.columns.type'),
      sortFunction: sortByUserStatus,
    },
  ];

  return (
    <div className='zestur-card'>
      <div className='mt-5'>
        <CandidateTableFilter
          filterByStatus={filterByStatus}
          filterByOffer={filterByOffer}
        />
      </div>
      <CandidatesTable
        maxAmount={maxAmount}
        setAmount={setMaxAmountHandler}
        searchValue={searchValue}
        pageNumber={pageNumber}
        setPageNumberHandler={setPageNumberHandler}
        uncheckAll={uncheckAll}
        checkAll={checkAll}
        candidates={displayedCandidates}
        columns={columns}
        handleRowCheck={handleRowCheck}
        selectAll={selectAll}
        statusValue={statusValue}
      />
    </div>
  );
};

export default ApplicantsCompany;
