import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../../_config/config';
import { RootState } from '../store';
import tokenService from '../../_services/token.service';
import Vacancy from '../../models/vacancy.interface';
import { decodeToken } from '../../models/decode-token';

export interface CompanyState {
  companyId: string;
  name: string;
  industrialSector: string;
  website: string;

  address: string;
  addressNumber: string;
  addressStair: string;
  addressFloor: string;
  city: string;
  country: string;

  businessName: string;
  nif: string;
  description: string;

  uploadedAvatar: File | null;
  email: string;

  firstName: string;
  lastName: string;
  password: string;
  phoneCode: string;
  phoneNumber: string;
  phone: string;
  logo: string;
  offers: Vacancy[];
  cover: string;
  plan?: string;

  is_verified: boolean;
  terms: boolean;
  registrationError?: string | null;
  tokenExhausted: boolean;
  companyDoNotExists: boolean;
  request_counter?: {
    gpt_4_requests: number;
    counter_expires_at: string;
  };
}

const initialState: CompanyState = {
  companyId: '',
  name: '',
  industrialSector: '',
  website: '',

  address: '',
  addressNumber: '',
  addressStair: '',
  addressFloor: '',
  city: '',
  country: '',

  businessName: '',
  nif: '',
  description: '',

  uploadedAvatar: null,

  email: '',
  firstName: '',
  lastName: '',
  phoneCode: '+34',
  phoneNumber: '',
  phone: '',
  offers: [],

  password: '',
  logo: '',
  cover: '',
  plan: '',

  is_verified: false,
  terms: false,
  registrationError: '',
  tokenExhausted: false,
  companyDoNotExists: false,
};

export const getCompany = createAsyncThunk('company/getCompany', async () => {
  const token = tokenService.getLocalAccessToken();
  const decodedToken = decodeToken(token);

  const response = await axios.get(
    `${config.SERVER_URL}company/${decodedToken?.company_id}/`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

  return response.data.body;
});

export const createCompany = createAsyncThunk(
  'company/createCompany',
  async (_, { getState }) => {
    const state: RootState = getState() as RootState;
    const companyState = state.companyReducer;
    let currentLanguage = localStorage.getItem('lang');
    if (!currentLanguage || currentLanguage == 'sp') {
      currentLanguage = 'es';
    } else if (currentLanguage == 'pt') {
      currentLanguage = 'pr';
    }

    const payload = {
      first_name: companyState.firstName,
      last_name: companyState.lastName,
      password: companyState.password,
      email: companyState.email,
      name: companyState.name,
      sector: companyState.industrialSector,
      url: companyState.website,
      address: companyState.address,
      address_number: companyState.addressNumber,
      address_floor: companyState.addressFloor,
      address_stair: companyState.addressStair,
      city: companyState.city,
      country: companyState.country,
      business_name: companyState.businessName,
      nif: companyState.nif,
      description: companyState.description,
      phone: companyState.phoneCode + companyState.phoneNumber,
    };

    const response = await axios.post(config.SERVER_URL + 'company/?current_language=' + currentLanguage, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
);

export const uploadCompanyLogo = createAsyncThunk(
  'company/uploadLogo',
  async (_, { getState }) => {
    const token = tokenService.getLocalAccessToken();
    const decodedToken = decodeToken(token);
    const state: RootState = getState() as RootState;

    const response = await axios.post(
      config.SERVER_URL + 'company/' + decodedToken?.company_id + '/logo/',
      { logo: state.companyReducer.uploadedAvatar },
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }
);

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },

    setSector: (state, action) => {
      state.industrialSector = action.payload;
    },

    setWebsite: (state, action) => {
      state.website = action.payload;
    },

    setCompanyAddress: (state, action) => {
      state.address = action.payload.address;
      state.addressFloor = action.payload.addressFloor;
      state.addressNumber = action.payload.addressNumber;
      state.addressStair = action.payload.addressStair;
      state.city = action.payload.city;
      state.country = action.payload.country;
    },

    setBusinessName: (state, action) => {
      state.businessName = action.payload;
    },

    setNif: (state, action) => {
      state.nif = action.payload;
    },

    setCompanyDescription: (state, action) => {
      state.description = action.payload;
    },

    setCompanyAvatar: (state, action) => {
      state.uploadedAvatar = action.payload;
    },

    setCompanyContactInfo: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.phoneCode = action.payload.phoneCode;
      state.phoneNumber = action.payload.phoneNumber;
      state.phone = action.payload.phoneCode + action.payload.phoneNumber;
    },

    setCompanyPassword: (state, action) => {
      state.password = action.payload;
    },

    setTerms: (state, action) => {
      state.terms = action.payload;
    },

    setCompanyTokenExhausted: (state, action) => {
      state.tokenExhausted = action.payload;
    },

    setCompanyExistsError: (state, action) => {
      state.companyDoNotExists = action.payload;
    },

    setUpdatedCompanyData: (state, action) => {
      state.businessName = action.payload.business_name;
      state.address = action.payload.address;
      state.city = action.payload.city;
      state.description = action.payload.description;
      state.logo = action.payload.logo;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.firstName = action.payload.first_name;
      state.lastName = action.payload.last_name;
      state.nif = action.payload.nif;
      state.website = action.payload.url;
      state.name = action.payload.name;
      state.industrialSector = action.payload.sector;
    },

    increaseLimitCounter: (state, action) => {
      if (!state.request_counter) return;

      state.request_counter.gpt_4_requests += action.payload;
    },

    resetCompanyData: () => initialState,
  },
  extraReducers(builder) {
    builder
      // Create company
      .addCase(createCompany.fulfilled, (state, _) => {
        state.registrationError = null;
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.registrationError = action.error.message;
        console.error(action.error);
      })

      // Get company
      .addCase(getCompany.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.tokenExhausted = false;
        state.companyDoNotExists = false;

        state.companyId = action.payload.id;
        state.name = action.payload.name;
        state.firstName = action.payload.first_name;
        state.lastName = action.payload.last_name;
        state.phoneCode = action.payload.phone.substring(0, 3);
        state.phoneNumber = action.payload.phone.substring(3);
        state.phone = action.payload.phone;
        state.address = action.payload.address;
        state.website = action.payload.url;
        state.addressFloor = action.payload.address_floor;
        state.addressNumber = action.payload.address_number;
        state.addressStair = action.payload.address_stair;
        state.city = action.payload.city;
        state.country = action.payload.country;
        state.nif = action.payload.nif;
        state.description = action.payload.description;
        state.industrialSector = action.payload.sector;
        state.businessName = action.payload.business_name;
        state.email = action.payload.email;
        state.logo = action.payload.logo;
        state.cover = action.payload.cover;
        state.offers = action.payload.offers;
        state.plan = action.payload.plan;
        state.request_counter = action.payload.request_counter;
        state.is_verified = action.payload.is_verified;

        state.offers.map((item) => (item.checked = false));
      })

      .addCase(getCompany.rejected, (state, action) => {
        if (action.error.message?.includes('403')) {
          state.tokenExhausted = true;
        }
      });
  },
});

export const {
  setName,
  setWebsite,
  setSector,
  setCompanyAddress,
  setBusinessName,
  setCompanyDescription,
  setNif,
  setCompanyAvatar,
  setCompanyContactInfo,
  setCompanyPassword,
  setTerms,
  setCompanyTokenExhausted,
  setUpdatedCompanyData,
  setCompanyExistsError,
  resetCompanyData,
  increaseLimitCounter,
} = companySlice.actions;
export default companySlice.reducer;
