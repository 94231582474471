import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../../components/breadcrumbs/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';
import DownloadResumeButton from '../../../../components/ui/download-resume-button/DownloadResumeButton';
import { PDFDownloadLink } from '@react-pdf/renderer';
import MatchDocument from '../../../../components/match-document/MatchDocument';
import { ASSETS_URL } from '../../../../constants';
import downloadIcon from '../../../../assets/icons/ic_download.svg';
import candidateImage from '../../../../assets/icons/avatar-placeholder.svg';
import { fetchMatchData } from '../../../../store/slices/matchDocumentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { useLocation } from 'react-router-dom';
import { AIModels } from '../../../../constants/constants';
import { checkIfAdvancedMatch } from '../../../../utils/check-if-advanced-match';

interface Props {
  data: any;
  offerId: string;
  candidateId: string;
}

const ApplicantDetailCandidateCard = ({
  data,
  offerId,
  candidateId,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [pathSegments, setPathSegments] = useState<string[]>([]);
  const matchData = useSelector(
    (state: RootState) => state.matchDocumentReducer.matchData
  );
  const company = useSelector((state: RootState) => state.companyReducer);
  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    setPathSegments(pathSegments.slice(1));

    if (!offerId || !candidateId) return;
    dispatch(fetchMatchData({ candidate_id: candidateId, offer_id: offerId }));
  }, []);

  return (
    <div className='zestur-card'>
      <Breadcrumbs
        names={[
          t('breadcrumbs.home'),
          t('breadcrumbs.candidates'),
          data ? `${data?.first_name + ' ' + data?.last_name}` : '',
        ]}
        segments={[
          pathSegments[0],
          pathSegments[1],
          pathSegments[2] + '/' + pathSegments[3],
        ]}
      />

      <div className='candidate-profile position-relative'>
        {/* <div className='position-absolute top-0 end-0'>
      <SaveButton
        id={id}
        toggleSave={toggleSaveHandler}
        saved={data?.saved}
      />
    </div> */}
        <Image
          className='candidate-profile__avatar object-fit-cover'
          src={data?.avatar || candidateImage}
        />
        <h2 className='h1'>
          {data?.first_name} {data?.last_name}
        </h2>
        <p>{data?.experience[0]?.job_position}</p>
      </div>

      <div className='mt-5 text-main-black'>
        {data?.seniorityLevel && (
          <>
            <h5 className='fw-semibold'>
              {t('candidateInfoComponent.seniority')}
            </h5>
            <p>{data?.seniorityLevel}</p>
          </>
        )}
        {data?.description?.length > 1 && (
          <>
            <h5 className='fw-semibold mt-4'>
              {t('candidateInfoComponent.description')}
            </h5>
            <p>{data?.description}</p>
          </>
        )}
        <h5 className='fw-semibold mt-4 '>
          {t('candidateInfoComponent.email')}
        </h5>
        <p className='text-break'>{data?.email}</p>
        <h5 className='fw-semibold mt-4'>
          {t('candidateInfoComponent.phone')}
        </h5>
        <p>{data?.phone}</p>
        {data?.address && (
          <>
            <h5 className='fw-semibold mt-4'>
              {t('candidateInfoComponent.location')}
            </h5>
            <p>{data?.address}</p>
          </>
        )}
        <div className='d-flex align-items-center justify-content-center mt-4 mb-2 gap-4'>
          {data?.cv[0] && (
            <DownloadResumeButton fileUrl={data.cv.at(-1)?.file || ''} />
          )}

          {matchData && checkIfAdvancedMatch(matchData.ai_model) && (
            <PDFDownloadLink
              className='download'
              style={{ textDecoration: 'none' }}
              document={
                <MatchDocument
                  matchData={matchData}
                  company={company}
                  name={data?.first_name + ' ' + data?.last_name}
                />
              }
              fileName='report.pdf'
            >
              {({ blob, url, loading, error }) => (
                <button className='download' type='button'>
                  <img
                    src={ASSETS_URL + downloadIcon}
                    alt='download'
                    className='blue-filter'
                  />
                  {t('matchDownloadButton')}
                </button>
              )}
            </PDFDownloadLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicantDetailCandidateCard;
