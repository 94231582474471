import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import SearchBar from '../../../components/shared/search-bar/SearchBar';
import './Request.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import Vacancy from '../../../models/vacancy.interface';
import mapPin from '../../../assets/icons/map-pin-black.svg';
import sort from '../../../assets/icons/basil-sort.svg';
import HomeFilterButton from '../../../components/home-filter-button/HomeFilterButton';
import DropdownPopup from '../../../components/dropdown-popup/DropdownPopup';
import { useNavigate } from 'react-router-dom';
import { salaryRange } from '../../../utils/format-salary';
import { requestedOffersList } from '../../../store/slices/candidateSlice';
import { useOutsideHandler } from '../../../utils/outside-click-handler';
import { useTranslation } from 'react-i18next';
import companyImage from '../../../assets/icons/avatar-placeholder.svg';

const RequestComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const candidate = useSelector((state: RootState) => state.candidateReducer);
  const tableHeaders: string[] = t('candidateRequestPage.tableHeaders', {
    returnObjects: true,
  });
  const wrapperRef = useRef<HTMLInputElement>(null);
  useOutsideHandler(wrapperRef, setOpen);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(requestedOffersList());
  }, []);

  function setOpen(isOpen: boolean): void {
    setIsDropdownOpen(isOpen);
  }

  useEffect(() => {
    console.log(candidate.requestedVacanciesSearch);

    // dispatch(requestedOffersList({searchValue: candidate.requestedVacanciesSearch}))
  }, [candidate.requestedVacanciesSearch]);

  return (
    <>
      <Row className='mobile-hidden'>
        <Col md={6}>
          <SearchBar location='request' />
        </Col>
      </Row>
      <Row>
        <Col className='mobile-hidden'>
          <div className='zestur-card'>
            <h4 className='text-main-black'>
              {t('candidateRequestPage.title')}
            </h4>

            <table className='request-table w-100'>
              <thead>
                <tr>
                  {tableHeaders.map((item) => (
                    <th key={item}>{item}</th>
                  ))}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {candidate.requestedVacancies.map(
                  (item: Vacancy, index: number) => (
                    <tr key={item.id || index}>
                      <td>
                        <div className='d-flex align-items-center gap-2'>
                          <img
                            className='logo'
                            src={item.company.logo || companyImage}
                            alt='logo'
                          />
                          <p>{item.company.name}</p>
                        </div>
                      </td>
                      <td>
                        <p>{item.position} </p>
                      </td>
                      <td>
                        <div className='d-flex align-items-center gap-2'>
                          <img
                            width={20}
                            height={20}
                            src={mapPin}
                            alt='location'
                          />
                          <p>{item.location}</p>
                        </div>
                      </td>
                      <td>
                        <p className='font-medium'>
                          {salaryRange(
                            +item.salary_min,
                            +item.salary_max,
                            t('salary.minPrefix'),
                            t('salary.maxPrefix')
                          )}
                        </p>
                      </td>
                      <td>
                        <p className='font-medium'>{item.created_at}</p>
                      </td>
                      <td>
                        <Button
                          onClick={() => navigate(`/candidate/${item.id}`)}
                        >
                          {t('candidateRequestPage.navigationButton')}
                        </Button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </Col>

        <Col className='no-mobile-hidden'>
          <div className='zestur-card'>
            <h4 className='text-main-black'>
              {t('candidateRequestPage.title')}
            </h4>

            <div
              className='no-mobile-hidden d-flex mt-4 justify-content-end'
              ref={wrapperRef}
            >
              <HomeFilterButton tagList={[]} selectOption={() => {}} />
              <button
                className='sort__button-mobile no-mobile-hidden'
                onClick={() => setIsDropdownOpen((prevValue) => !prevValue)}
              >
                <img src={sort} alt='sort' className='blue-filter' />
                <span>{t('candidateHomePage.sort.button')}</span>
              </button>

              {isDropdownOpen && (
                <DropdownPopup optionList={[]} selectOption={() => {}} />
              )}
            </div>

            <div className='d-flex flex-column gap-2'>
              {candidate.requestedVacancies.map(
                (item: Vacancy, index: number) => (
                  <div
                    key={item.id || index}
                    className='list-item-wrapper d-flex justify-content-between align-items-center'
                  >
                    <div className='d-flex align-items-center gap-2'>
                      <img
                        className='logo'
                        src={item.company.logo}
                        alt='logo'
                      />
                      <p>{item.company.name}</p>
                    </div>
                    <Button onClick={() => navigate(`/candidate/${item.id}`)}>
                      {t('candidateRequestPage.mobileNavigation')}
                    </Button>
                  </div>
                )
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RequestComponent;
