import React from 'react';
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import { MatchData } from '../../models/match-data.interface';
import { useTranslation } from 'react-i18next';
import { decodeWithUnicode } from '../../utils/decode-with-unicode';
import { CompanyState } from '../../store/slices/companySlice';

interface Props {
  matchData: MatchData;
  company: CompanyState;
  name: string;
}

const blue = '#1F487D';
const innerBorder = '#b6bfd6';
const styles = StyleSheet.create({
  body: { paddingTop: 35, paddingBottom: 65, paddingHorizontal: 35 },
  section: {
    margin: 5,
    border: `1px solid ${blue}`,
    borderTop: 'none',
  },
  header: {
    position: 'relative',
    margin: '0 5px',
    backgroundColor: '#16365C',
    height: 100,
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    marginBottom: 6,
  },

  matchInfo: {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    gap: 12,
    marginTop: 6,
  },
  image: {
    objectFit: 'cover',
  },
  logo: {
    width: 30,
    height: 30,
    objectFit: 'cover',
  },
  title: {
    position: 'absolute',
    color: 'white',
    fontSize: 18,
    margin: 10,
  },
  reportSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 8,
    paddingTop: 5,
    paddingBottom: 5,
    borderTop: `1px solid ${innerBorder}`,
  },
  middleCol: {
    width: '10%',
    textAlign: 'center',
    borderRight: `1px solid ${innerBorder}`,
  },
  reasonCol: {
    width: '70%',
    padding: '0 12px',
  },
  weighCol: {
    width: '10%',
    textAlign: 'center',
    borderLeft: `1px solid ${innerBorder}`,
  },
});

const MatchDocument = ({ matchData, company, name }: Props) => {
  const { t } = useTranslation();

  const matchSections = [
    {
      title: t('matchDocument.labels.title'),
      match: t('matchDocument.labels.match'),
      reason: '',
      weight: t('matchDocument.labels.weight'),
    },
    {
      title: t('createOffer.card2.key1'),
      match: matchData.work_experience,
      reason: matchData.response?.match?.[0]?.reason_work_experience,
      weight: matchData.offerRating?.experience_rating,
    },
    {
      title: t('vacancyDetails.labels.education'),
      match: matchData.training,
      reason: matchData.response?.match?.[0]?.reason_training,
      weight: matchData.offerRating?.training_rating,
    },
    {
      title: t('vacancyDetails.subtitles.language'),
      match: matchData.languages,
      reason: matchData.response?.match?.[0]?.reason_languajes,
      weight: matchData.offerRating?.language_rating,
    },
    {
      title: t('vacancyDetails.labels.quality'),
      match: matchData.qualities,
      reason: matchData.response?.match?.[0]?.reason_qualities,
      weight: matchData.offerRating?.qualities_rating,
    },
    {
      title: t('vacancyDetails.labels.capability'),
      match: matchData.abilities,
      reason: matchData.response?.match?.[0]?.reason_abilities,
      weight: matchData.offerRating?.capabilities_rating,
    },
    {
      title: t('vacancyDetails.labels.license'),
      match: matchData.driving_license,
      reason: matchData.response?.match?.[0]?.reason_license || '',
      weight: matchData.offerRating?.license_rating,
    },
  ];

  return (
    <Document>
      <Page style={styles.body} size={{ width: 1000, height: 500 }}>
        <View style={styles.header}>
          {company.cover && <Image src={company.cover} style={styles.image} />}
          <View style={styles.title}>
            <View style={styles.companyInfo}>
              {company.logo && <Image src={company.logo} style={styles.logo} />}
              <Text>{company.name}</Text>
            </View>
            <Text>
              {t('matchDocument.header')} {+(matchData.ai_match || 0) * 10}%
            </Text>

            <View style={styles.matchInfo}>
              <Text>
                {t('matchDocument.candidateTitle')}: {name}
              </Text>
              <Text>
                {t('matchDocument.offerTitle')}:{' '}
                {matchData.offerRating?.position}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          {matchSections.map((section, index: number) => (
            <View
              key={index}
              style={[
                styles.reportSection,
                !index ? { backgroundColor: blue, color: 'white' } : {},
                !(index % 2) && index ? { backgroundColor: '#F2F2F2' } : {},
                index <= 1 ? { borderColor: blue } : {},
                index === matchSections.length - 1
                  ? { borderBottomWidth: 2, borderBottomColor: blue }
                  : {},
              ]}
            >
              <Text style={[styles.middleCol]}>{section.title}</Text>
              <Text style={[styles.middleCol]}>{section.match || 0}</Text>
              <Text
                style={[
                  styles.reasonCol,
                  !index ? { textAlign: 'center' } : {},
                ]}
              >
                {index
                  ? decodeWithUnicode(section.reason || '')
                  : t('matchDocument.labels.reason')}
              </Text>
              <Text style={[styles.weighCol]}>{section.weight}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default MatchDocument;
